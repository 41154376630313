<template>
    <div>
        <title-tap :active-name="activeName" :tab-data="tabData" @selectInfo="selectInfo" />
        <div style="display:flex;align-items:center;padding-left:10px">
            <img src="@/assets/setting/tipIcon.png" width="20" height="20" />
            <div style="color:#AAAAAA;margin-left:5px">
                {{ activeName == 0 ? "工作时间内的线索超时的时间较短，务必及时联系！在设置工作时间时，请勿将午休时段或其他休息时段选中！" : "节假日期间分配的线索，超时倒计时在上班时开始计算" }}
            </div>
        </div>
        <div
            v-loading="loading"
            class="kaoqin"
            :style="{ height: activeName == 1 ? 'calc(100vh - 157px)' : 'calc(100vh - 200px)' }"
        >
            <div class="content">
                <!-- 工作时间标题 -->
                <!-- <div class="time" v-if="activeName == 0">
          <span>工作时间设置</span>
          <el-button type="primary" class="btn" size="mini" @click="save"
            >保存</el-button
          >
                </div>-->
                <!-- 可编辑的工作时间表格 -->
                <div v-if="activeName == 0" class="byted-weektime">
                    <div class="calendar" @mousedown="dian" @mousemove="yi" @mouseup="li">
                        <div class="tishi">
                            <div style="flex:1">
                                按下并
                                <font style="color:#4086EC">滑动鼠标框</font>并选出时间范围
                            </div>
                            <div style="display:flex;align-items:center">
                                <i />
                                <span>上班时间</span>
                            </div>
                        </div>
                        <table class="calendar-table" style="width: 610px">
                            <thead class="calendar-head">
                                <tr>
                                    <th rowspan="6" class="week-td">星期/时间</th>
                                    <th colspan="24">00:00 - 12:00</th>
                                    <th colspan="24">12:00 - 24:00</th>
                                </tr>
                                <tr>
                                    <td
                                        v-for="(item, index) in tableHeader"
                                        :key="index"
                                        colspan="2"
                                    >{{ item }}</td>
                                </tr>
                            </thead>
                            <tbody id="tableBody">
                                <div
                                    id="kuang"
                                    :style="{
                    width: kuangObj.width + 'px',
                    height: kuangObj.height + 'px',
                    top: kuangObj.top + 'px',
                    left: kuangObj.left + 'px',
                    bottom: kuangObj.bottom + 'px',
                    right: kuangObj.right + 'px'
                  }"
                                />
                                <tr>
                                    <td>星期一</td>
                                    <td
                                        v-for="(item, i) in rowUnit[0]"
                                        :key="i"
                                        class="calendar-atom-time"
                                        :class="item.class"
                                        @mousedown.prevent="handleMouseDown(i, 0)"
                                        @mouseup.prevent="handleMouseUp(i, 0)"
                                    />
                                </tr>
                                <tr>
                                    <td>星期二</td>
                                    <td
                                        v-for="(item, i) in rowUnit[1]"
                                        :key="i"
                                        class="calendar-atom-time"
                                        :class="item.class"
                                        @mousedown.prevent="handleMouseDown(i, 1)"
                                        @mouseup.prevent="handleMouseUp(i, 1)"
                                    />
                                </tr>
                                <tr>
                                    <td>星期三</td>
                                    <td
                                        v-for="(item, i) in rowUnit[2]"
                                        :key="i"
                                        class="calendar-atom-time"
                                        :class="item.class"
                                        @mousedown.prevent="handleMouseDown(i, 2)"
                                        @mouseup.prevent="handleMouseUp(i, 2)"
                                    />
                                </tr>
                                <tr>
                                    <td>星期四</td>
                                    <td
                                        v-for="(item, i) in rowUnit[3]"
                                        :key="i"
                                        class="calendar-atom-time"
                                        :class="item.class"
                                        @mousedown.prevent="handleMouseDown(i, 3)"
                                        @mouseup.prevent="handleMouseUp(i, 3)"
                                    />
                                </tr>
                                <tr>
                                    <td>星期五</td>
                                    <td
                                        v-for="(item, i) in rowUnit[4]"
                                        :key="i"
                                        class="calendar-atom-time"
                                        :class="item.class"
                                        @mousedown.prevent="handleMouseDown(i, 4)"
                                        @mouseup.prevent="handleMouseUp(i, 4)"
                                    />
                                </tr>
                                <tr>
                                    <td>星期六</td>
                                    <td
                                        v-for="(item, i) in rowUnit[5]"
                                        :key="i"
                                        class="calendar-atom-time"
                                        :class="item.class"
                                        @mousedown.prevent="handleMouseDown(i, 5)"
                                        @mouseup.prevent="handleMouseUp(i, 5)"
                                    />
                                </tr>
                                <tr>
                                    <td>星期日</td>
                                    <td
                                        v-for="(item, i) in rowUnit[6]"
                                        :key="i"
                                        class="calendar-atom-time"
                                        :class="item.class"
                                        @mousedown.prevent="handleMouseDown(i, 6)"
                                        @mouseup.prevent="handleMouseUp(i, 6)"
                                    />
                                </tr>
                                <!-- 
            <tr>
              <td colspan="49" class="td-table-tip">
                <div class="clearfix">
                  <span class="pull-left tip-text">请用鼠标点选时间段</span>
                  <a @click="clear" class="pull-right">清空</a>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="49" class="timeContent">
                <div v-for="(item,index) in timeStr" :key="index" v-show="item.length">
                  <span>{{weekDate[index+1]}}:</span>
                  <strong>
                    <span>{{item}}</span>
                  </strong>
                </div>
              </td>
                                </tr>-->
                            </tbody>
                        </table>
                        <div
                            id="container"
                            :style="{
                backgroundColor: back,
                height: h + 'px',
                width: w + 'px',
                position: 'absolute',
                left: left + 'px',
                top: top + 'px',
                opacity: 0.2,
                border: len + 'px dashed #000',
                zIndex: zI
              }"
                        />
                    </div>
                    <!-- 时间框 -->
                    <div class="time-select-area">
                        <div class="area-item">
                            <div style="width:5%">星期一:</div>
                            <el-row :gutter="20" style="width:100%">
                                <el-col v-for="(item, index) in monday" :key="index" :span="3">
                                    <div class="time-val">{{ item }}</div>
                                </el-col>
                                <el-col v-if="monday.length < 1" :span="3">
                                    <div class="time-val">暂无内容</div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="area-item">
                            <div style="width:5%">星期二:</div>
                            <el-row :gutter="20" style="width:100%">
                                <el-col v-for="(item, index) in tuesday" :key="index" :span="3">
                                    <div class="time-val">{{ item }}</div>
                                </el-col>
                                <el-col v-if="tuesday.length < 1" :span="3">
                                    <div class="time-val">暂无内容</div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="area-item">
                            <div style="width:5%">星期三:</div>
                            <el-row :gutter="20" style="width:100%">
                                <el-col v-for="(item, index) in wednesday" :key="index" :span="3">
                                    <div class="time-val">{{ item }}</div>
                                </el-col>
                                <el-col v-if="wednesday.length < 1" :span="3">
                                    <div class="time-val">暂无内容</div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="area-item">
                            <div style="width:5%">星期四:</div>
                            <el-row :gutter="20" style="width:100%">
                                <el-col v-for="(item, index) in thursday" :key="index" :span="3">
                                    <div class="time-val">{{ item }}</div>
                                </el-col>
                                <el-col v-if="thursday.length < 1" :span="3">
                                    <div class="time-val">暂无内容</div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="area-item">
                            <div style="width:5%">星期五:</div>
                            <el-row :gutter="20" style="width:100%">
                                <el-col v-for="(item, index) in friday" :key="index" :span="3">
                                    <div class="time-val">{{ item }}</div>
                                </el-col>
                                <el-col v-if="friday.length < 1" :span="3">
                                    <div class="time-val">暂无内容</div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="area-item">
                            <div style="width:5%">星期六:</div>
                            <el-row :gutter="20" style="width:100%">
                                <el-col v-for="(item, index) in saturday" :key="index" :span="3">
                                    <div class="time-val">{{ item }}</div>
                                </el-col>
                                <el-col v-if="saturday.length < 1" :span="3">
                                    <div class="time-val">暂无内容</div>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="area-item">
                            <div style="width:5%">星期日:</div>
                            <el-row :gutter="20" style="width:100%">
                                <el-col v-for="(item, index) in sunday" :key="index" :span="3">
                                    <div class="time-val">{{ item }}</div>
                                </el-col>
                                <el-col v-if="sunday.length < 1" :span="3">
                                    <div class="time-val">暂无内容</div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>

                    <!-- 按钮 -->
                    <!-- <div>
            <div
              v-for="(item, index) in timeStr"
              :key="index"
              v-show="item.length"
            >
              <span>{{ weekDate[index + 1] }}:</span>
              <strong>
                <span>{{ item }}</span>
              </strong>
            </div>
                    </div>-->
                    <!--  -->
                </div>
                <!-- 节假日设置标题 -->
                <div v-if="activeName == 1" class="time jie">
                    <span>节假日设置</span>
                    <el-button type="primary" class="btn" size="mini" @click="open">添加节日</el-button>
                </div>
                <!-- 节假日数据展示 -->
                <div v-if="activeName == 1" class="jielist">
                    <el-table :data="tableData" :height="tableHeight" style="width: 100%" border>
                        <el-table-column type="index" label="序号" width="50px" />
                        <el-table-column prop="name" label="节日名称" />
                        <el-table-column prop="startTime" label="开始时间" />
                        <el-table-column prop="endTime" label="结束时间" />
                        <el-table-column prop="remarks" label="备注" />
                        <el-table-column prop="createTime" label="创建时间" />
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-popover
                                    :ref="`popover-${scope.$index}`"
                                    placement="top"
                                    width="200"
                                >
                                    <p style="margin-bottom: 30px">确定删除节假日么？</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button
                                            size="mini"
                                            @click="
                        scope._self.$refs[`popover-${scope.$index}`].doClose()
                      "
                                        >取消</el-button>
                                        <span @click="deleteRow(scope.row)">
                                            <el-button
                                                type="primary"
                                                size="mini"
                                                @click="
                          scope._self.$refs[`popover-${scope.$index}`].doClose()
                        "
                                            >确定</el-button>
                                        </span>
                                    </div>
                                    <el-link
                                        slot="reference"
                                        :underline="false"
                                        style="color:#DE3C3C"
                                    >删除</el-link>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 新增节假日 -->
                <el-dialog
                    width="426px"
                    title="新增节假日"
                    class="new"
                    center
                    :visible.sync="dialogFormVisible"
                    :before-close="closeDialog"
                >
                    <el-form
                        ref="ruleForm"
                        :model="ruleForm"
                        :rules="rules"
                        label-width="100px"
                        class="demo-ruleForm"
                    >
                        <el-form-item label="节日名称：" prop="name">
                            <el-input
                                v-model="ruleForm.name"
                                size="medium"
                                style="width:90%"
                                placeholder="请输入内容"
                            />
                        </el-form-item>
                        <el-form-item label="日期选择：" prop="timeC">
                            <el-date-picker
                                v-model="ruleForm.timeC"
                                size="medium"
                                style="width:90%"
                                type="datetimerange"
                                value-format="timestamp"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="['00:00:00', '23:59:59']"
                                @change="time"
                            />
                        </el-form-item>
                        <el-form-item label="备注：" prop="remarks">
                            <el-input
                                v-model="ruleForm.remarks"
                                size="medium"
                                style="width:90%"
                                placeholder="请输入内容"
                                maxlength="128"
                                show-word-limit
                                type="textarea"
                                :rows="2"
                            />
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                        <el-button @click="closeDialog">取 消</el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
        <div v-if="activeName == 0" class="bottom-btn">
            <el-button size="medium" type="primary" @click="save">保存</el-button>
            <el-button size="medium" @click="resetTable">重置</el-button>
        </div>
    </div>
</template>

<script>
import {
    work_time,
    modify_work_time,
    holidays,
    add_holiday,
    delete_holiday,
} from '../../api/setting_time.js';
import { getYMDHMS } from '../../assets/js/time.js';

export default {
    name: 'TimeSelect',
    components: {
        'title-tap': () => import('../../components/templates/title-tap'),
    },
    data() {
        return {
            tableData: [],
            tableHeader: [
                '00',
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
            ],
            weekDate: {
                1: '星期一',
                2: '星期二',
                3: '星期三',
                4: '星期四',
                5: '星期五',
                6: '星期六',
                7: '星期日',
            },
            rowUnit: [], //每一个单元格
            timeContent: [], //选中的时间段原始数据
            timeSection: [], //时间段，可以返回给后台的数据
            timeStr: [], //时间段，前端显示的数据
            beginDay: 0,
            beginTime: 0,
            downEvent: false,
            kuangObj: {
                width: 0,
                height: 0,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                oldLeft: 0,
                oldTop: 0,
                flag: false,
            },
            py: '',
            px: '',
            back: '#31676f',
            h: '',
            w: '',
            top: '',
            left: '',
            len: 0,
            zI: -1,
            dialogFormVisible: false,
            jiename: '',
            ruleForm: {
                name: '',
                timeC: '',
                remarks: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                    {
                        min: 1,
                        max: 32,
                        message: '长度是1-32个字符之间',
                        trigger: 'blur',
                    },
                    { validator: this.formValidation.heading, trigger: 'blur' },
                ],
                timeC: [
                    {
                        required: true,
                        message: '请选择时间段',
                        trigger: 'blur',
                    },
                ],
            },
            activeName: 0,
            tabData: ['工作时间设置', '节假日设置'],
            monday: [], //周一
            tuesday: [], //周二
            wednesday: [], //周三
            thursday: [], //周四
            friday: [], //周五
            saturday: [], //周六
            sunday: [], //周日
            loading: false,
            tableHeight: window.innerHeight - 270,
        };
    },
    created() {
        window.addEventListener('resize', this.getHeight);
        this.init();
    },
    mounted() {
        this.xr();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getHeight);
    },
    watch: {},
    methods: {
        getHeight() {
            // this.tableHeight = this.tableData.length > 0 ? window.innerHeight - 250 : "";
            this.tableHeight = window.innerHeight - 270;
        },
        //节假日列表渲染
        xr() {
            holidays().then((res) => {
                this.loading = true;
                if (res.code == 0) {
                    console.log('res.data', res.data);
                    this.tableData = res.data;
                    for (let i = 0; i < this.tableData.length; i++) {
                        let timeArr = [];
                        timeArr[0] = getYMDHMS(this.tableData[i].startTime);
                        timeArr[1] = ' ~ ';
                        timeArr[2] = getYMDHMS(this.tableData[i].endTime);
                        this.tableData[i].time = timeArr;
                        this.tableData[i].createTime = getYMDHMS(
                            this.tableData[i].createTime
                        );
                        this.tableData[i].startTime = getYMDHMS(
                            this.tableData[i].startTime
                        );
                        this.tableData[i].endTime = getYMDHMS(
                            this.tableData[i].endTime
                        );
                    }
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //删除节假日
        deleteRow(row) {
            delete_holiday({ name: row.name }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '删除节假日成功',
                        type: 'success',
                    });
                    this.xr();
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //日期选择
        time() {
            this.ruleForm.startTime = this.ruleForm.timeC[0] / 1000;
            this.ruleForm.endTime = this.ruleForm.timeC[1] / 1000;
        },
        //打开添加节假日窗口
        open() {
            this.dialogFormVisible = true;
        },
        //添加节假日
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    add_holiday(this.ruleForm).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: '添加成功',
                                type: 'success',
                            });
                            (this.ruleForm = {
                                name: '',
                                timeC: '',
                                remarks: '',
                            }),
                                this.xr();
                            this.dialogFormVisible = false;
                        } else {
                            this.$message.error(res.error);
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        //鼠标开始选择选择
        dian(event) {
            this.px = event.pageX; //获取x坐标
            this.py = event.pageY;
        },
        //鼠标选择效果操作
        yi(event) {
            if (this.px == '' || this.py == '') {
                return;
            }
            let px1 = this.px;
            let px2 = this.py;
            this.left = event.pageX;
            this.top = event.pageY;
            this.h = this.top - this.py;
            this.w = this.left - this.px;
            let hc = -this.h;
            let wc = -this.w;
            this.len = 1;
            this.zI = 2;
            this.back = '#31676f';
            if (this.h < 0 && this.w >= 0) {
                this.h = hc;
                this.left = px1;
            } else if (this.h >= 0 && this.w < 0) {
                this.w = wc;
                this.top = px2;
            } else if (this.h < 0 && this.w < 0) {
                this.h = hc;
                this.w = wc;
            } else {
                this.left = this.px;
                this.top = this.py;
            }
            if (this.w < 0) {
                this.w = 0 - this.w;
            }
            if (this.h < 0) {
                this.h = 0 - this.h;
            }
        },
        //鼠标选择后离开
        li() {
            this.px = '';
            this.py = '';
            this.h = '';
            this.w = '';
            this.top = '';
            this.left = '';
            this.len = 0;
            this.back = '';
            this.zI = -1;
        },
        handleTime(val) {
            this.monday = val[0] ? val[0].split(',') : '';
            this.tuesday = val[1] ? val[1].split(',') : '';
            this.wednesday = val[2] ? val[2].split(',') : '';
            this.thursday = val[3] ? val[3].split(',') : '';
            this.friday = val[4] ? val[4].split(',') : '';
            this.saturday = val[5] ? val[5].split(',') : '';
            this.sunday = val[6] ? val[6].split(',') : '';
        },
        //选择和反选和渲染表格操作
        init() {
            work_time().then((res) => {
                let cccc = res.data;
                for (let i = 0; i < 7; i++) {
                    let arr = [];
                    for (let j = 0; j < 48; j++) {
                        arr.push({ class: null, timeData: j });
                    }
                    this.rowUnit.push(arr);
                    this.timeContent.push({ arr: [] });
                    this.timeSection.push([]);
                    this.timeStr = [];
                }
                if (res.data.length <= 0) {
                    this.save();
                    location.reload();
                }
                //附上默认值
                if (cccc.length > 0) {
                    this.timeSection = cccc;
                    //先将后端返回过来的值赋予到timeSection，就是保持同步
                    let ccc = JSON.parse(JSON.stringify(cccc));
                    //这边声明一个变量就是深拷贝一下，因为后面的操作会导致  cccc  有变化，所以这边做一下处理
                    //我们得通过  ccc  来处理 默认的 rowUnit，timeContent，timeStr
                    //因为是通过这三个东西来要渲染到页面上，也就是我们所说的默认值
                    for (let i = 0; i < ccc.length; i++) {
                        this.timeStr.push(this.timeToStr(ccc[i]));
                        this.handleTime(this.timeStr);
                        //!这个函数是下面的有，拿出来就可以转换，就转换成 07:00~15:00  这样的前端展示的格式
                        for (let j = 0; j < ccc[i].length; j++) {
                            ccc[i][j][0] = ccc[i][j][0] * 2;
                            ccc[i][j][1] = ccc[i][j][1] * 2;
                            //这边  ×2  是因为是48个格子，我们之前cccc的数据都是除2之后的，所以到这边要恢复一下，才能得到表格视图渲染的数据
                            for (let k = ccc[i][j][0]; k < ccc[i][j][1]; k++) {
                                if (this.rowUnit[i][k] !== null) {
                                    this.rowUnit[i][k].class = 'ui-selected';
                                    //!这边就是样式加上去
                                    this.timeContent[i].arr.push(
                                        this.rowUnit[i][k].timeData
                                    );
                                    //!这边  timeContent 就是要保持数据的一个同步
                                }
                            }
                        }
                    }
                }
            });
        },
        handleMouseDown(i, day) {
            this.downEvent = true; //按下时鼠标不在范围内则不算
            this.beginDay = day;
            this.beginTime = i;
        },
        //获取选择的表格的数据
        handleMouseUp(i, day) {
            let _this = this;
            let begin = this.beginTime;
            let start = begin <= i ? begin : i; //x轴 起点
            let length = Math.abs(begin - i);
            let end = start + length; //x轴 终点
            let dayStart = this.beginDay <= day ? this.beginDay : day; //y轴 起点
            let dayLength = Math.abs(this.beginDay - day);
            let dayEnd = dayStart + dayLength; //y轴 终点
            //当框选范围内所有块都是选中状态时,执行反选
            function isAdd() {
                for (let x = dayStart; x < dayEnd + 1; x++) {
                    for (let y = start; y < end + 1; y++) {
                        if (_this.rowUnit[x][y].class == null) return true;
                    }
                }
                return false;
            }
            //当点击事件是在table内才触发选取数据操作
            if (this.downEvent) {
                //选时间段
                if (isAdd()) {
                    //没选中的全都选上
                    for (let x = dayStart; x < dayEnd + 1; x++) {
                        for (let y = start; y < end + 1; y++) {
                            if (this.rowUnit[x][y].class == null) {
                                this.rowUnit[x][y].class = 'ui-selected';
                                this.timeContent[x].arr.push(
                                    this.rowUnit[x][y].timeData
                                );
                            }
                        }
                    }
                } else {
                    //反选
                    for (let x = dayStart; x < dayEnd + 1; x++) {
                        for (let y = start; y < end + 1; y++) {
                            if (this.rowUnit[x][y].class == 'ui-selected') {
                                this.rowUnit[x][y].class = null;
                                let c = this.rowUnit[x][y].timeData;
                                let kong = '';
                                for (
                                    let i = 0;
                                    i < this.timeContent[x].arr.length;
                                    i++
                                ) {
                                    if (c == this.timeContent[x].arr[i]) {
                                        kong = i;
                                    }
                                }
                                this.timeContent[x].arr.splice(kong, 1);
                            }
                        }
                    }
                }
                //过滤时间段,将临近的时间段合并
                this.filterTime(dayStart, dayEnd);
            }
            this.downEvent = false;
        },
        toStr(num) {
            if (Number.isInteger(num)) {
                let str = num < 10 ? '0' + num : num.toString();
                return str + ':00';
            } else {
                let str =
                    Math.floor(num) < 10
                        ? '0' + Math.floor(num)
                        : Math.floor(num).toString();
                return str + ':30';
            }
        },
        timeToStr(arr) {
            //把数组转成方便人看到字符串
            let str = '';
            arr.forEach((arr, index) => {
                let str1 = '';
                if (index == 0) {
                    str1 = this.toStr(arr[0]) + '~' + this.toStr(arr[1]);
                } else {
                    str1 =
                        ' , ' + this.toStr(arr[0]) + '~' + this.toStr(arr[1]);
                }
                str += str1;
            });
            return str;
        },
        //计算并可读化选择表格的数据
        filterTime(start, end) {
            //选中的x,y坐标信息 x:0-47  y:0-6
            function sortCut(arr) {
                //提取连续的数字
                let result = [];
                arr.forEach(function (v, i) {
                    let temp = result[result.length - 1];
                    if (!i) {
                        result.push([v]);
                    } else if (v % 1 === 0 && v - temp[temp.length - 1] == 1) {
                        temp.push(v);
                    } else {
                        result.push([v]);
                    }
                });
                return result;
            }
            //排序,分割成
            for (let i = start; i < end + 1; i++) {
                let arr1 = sortCut(
                    this.timeContent[i].arr.sort((a, b) => a - b)
                );
                let arr2 = [];
                arr1.forEach((arr) => {
                    //转成带小数点的时间段,以及供前端显示的字符串
                    let arr3 = [];
                    arr3.push(arr[0] / 2);
                    arr3.push(arr[arr.length - 1] / 2 + 0.5);
                    arr2.push(arr3);
                });
                this.timeStr[i] = this.timeToStr(arr2);
                this.handleTime(this.timeStr);
                this.timeSection[i] = arr2;
            }
            // this.save(1);
        },
        //保存操作
        save(status) {
            modify_work_time(this.timeSection).then((res) => {
                if (status != 1) {
                    if (res.code == 0) {
                        this.$message({
                            message: '保存成功',
                            type: 'success',
                        });
                    } else {
                        this.$message.error(res.error);
                    }
                }
            });
        },
        // 重置操作
        resetTable() {
            // 清空传给后台的本地选中
            let newTimeContent = [];
            this.timeContent.forEach((item) => {
                item.arr = [];
                newTimeContent.push(item);
            });
            this.timeContent = newTimeContent;
            // 清空展示前端的数据
            this.timeStr = [];
            this.rowUnit.forEach((item) => {
                item.forEach((i) => {
                    if (i.class == 'ui-selected') {
                        i.class = null;
                    }
                });
            });
            this.handleTime(this.timeStr);
            // 清空传给后台的数据
            let newArr = [];
            this.timeSection.forEach((item) => {
                item = [];
                newArr.push(item);
            });
            this.timeSection = newArr;
        },
        closeDialog() {
            this.dialogFormVisible = false;
            this.$refs.ruleForm.resetFields();
            console.log(this.ruleForm);
        },
        // 头部切换
        selectInfo(val) {
            console.log(val.index);
            this.activeName = val.index;
        },
    },
};
</script>

<style lang="less" scoped>
.kaoqin {
    padding: 10px 10px 0px;
    // height: calc(100vh - 220px);
    overflow: hidden;
    .content {
        overflow-y: scroll;
        background: white;
        width: 100%;
        height: 100%;
        // height: calc(100vh - 180px);
        padding: 20px;
    }
    .time {
        padding: 10px;
        // background: #f5f7f9;
        margin-bottom: 10px;
        border-radius: 4px;
        position: relative;
        span {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
        }
        .btn {
            position: absolute;
            bottom: 8px;
            left: 110px;
            // right: 10px;
        }
    }
    .jie {
        text-align: left;
        // margin-top: 40px;
    }
}
.jielist {
    border: 1px solid rgba(219, 219, 219, 1);
    ::v-deep .el-table thead tr th {
        background: rgba(245, 247, 249, 1);
        color: #333;
        font-weight: 900;
    }
    ::v-deep .el-table td {
        padding: 8px 0;
    }
}
.new {
    text-align: left;
    ::v-deep .el-input {
        width: 200px;
    }
    ::v-deep .el-textarea {
        width: 90%;
    }
    ::v-deep .el-range-separator {
        width: 8%;
    }
}
.byted-weektime {
    // display: flex;
    text-align: center;
    .tishi {
        display: flex;
        align-items: center;

        margin-bottom: 20px;

        i {
            display: inline-block;
            background: #2f88ff;
            width: 40px;
            height: 15px;
        }
        span {
            font-size: 14px;
            display: inline-block;
            margin-left: 10px;
        }
    }
}
.byted-weektime .calendar {
    -webkit-user-select: none;
    display: inline-block;
}
.byted-weektime .calendar .calendar-table {
    border-collapse: collapse;
    border-radius: 4px;
}
.byted-weektime .calendar .calendar-table {
    width: 1200px !important;
}
.byted-weektime .calendar .calendar-table tr .calendar-atom-time:hover {
    background: #ccc;
}
.byted-weektime .calendar .calendar-table tr .ui-selected {
    background: #2f88ff;
}
.byted-weektime .calendar .calendar-table tr .ui-selected:hover {
    background: #2f88ff;
}
.byted-weektime .calendar .calendar-table tr,
.byted-weektime .calendar .calendar-table td,
.byted-weektime .calendar .calendar-table th {
    border: 1px solid #ccc;
    font-size: 12px;
    text-align: center;
    line-height: 1.8em;
    -webkit-transition: background 200ms ease;
    -moz-transition: background 200ms ease;
    -ms-transition: background 200ms ease;
    transition: background 200ms ease;
}
.byted-weektime .calendar .calendar-table tbody tr {
    height: 40px;
}
.byted-weektime .calendar .calendar-table tbody tr td:first-child {
    // background: #f8f9fa;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
.byted-weektime .calendar .calendar-table thead th,
.byted-weektime .calendar .calendar-table thead td {
    background: #f8f9fa;
}
.byted-weektime .calendar .calendar-table .td-table-tip {
    line-height: 2.4em;
    padding: 0 12px 0 19px;
    background: #fff !important;
}
.byted-weektime .calendar .calendar-table .td-table-tip .clearfix {
    height: 46px;
    line-height: 46px;
}
.byted-weektime .calendar .calendar-table .td-table-tip .pull-left {
    font-size: 14px;
    color: #333333;
}
.byted-weektime .week-td {
    width: 75px;
    padding: 20px 0;
}
.byted-weektime a {
    cursor: pointer;
    color: #2f88ff;
    font-size: 14px;
}
#kuang {
    position: absolute;
    background-color: blue;
    opacity: 0.3;
}
.time-select-area {
    // padding: 0 11.2%;
    width: 1200px;
    margin: 0 auto;
    text-align: left;
    .area-item {
        margin: 10px 0;
        display: flex;
        // align-items: center;
        .time-val {
            // background-color: #000;
            border: 1px solid rgba(220, 223, 230, 1);
            padding: 5px;
            border-radius: 5px;
            margin: 5px;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}
.bottom-btn {
    background: #fff;
    padding: 5px;
    border-top: 1px solid #ccc;
    text-align: center;
    margin: 0 10px;
}
</style>

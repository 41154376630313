import request from './request.js';
import public_request from './public_request';
import public_request1 from './public_request1';
//列表工作时间表
export function work_time() {
    return request({
        url: '/setting/attendance/work-time',
        method: 'GET',
        params: public_request(),
    });
}
//设置工作时间
export function modify_work_time(arr) {
    return request({
        url: '/setting/attendance/work-time',
        method: 'put',
        data: public_request1(arr),
    });
}
//获取节假日列表
export function holidays() {
    return request({
        url: '/setting/attendance/holidays',
        method: 'GET',
        params: public_request(),
    });
}
//添加节假日
export function add_holiday({ name, startTime, endTime, remarks }) {
    return request({
        url: '/setting/attendance/holiday',
        method: 'post',
        data: public_request({
            name,
            startTime,
            endTime,
            remarks,
        }),
    });
}
//删除节假日
export function delete_holiday({ name, startTime, endTime, remarks }) {
    return request({
        url: '/setting/attendance/holiday',
        method: 'delete',
        data: public_request({
            name,
            startTime,
            endTime,
            remarks,
        }),
    });
}

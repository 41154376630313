import { render, staticRenderFns } from "./kaoqin.vue?vue&type=template&id=2591bfbf&scoped=true&"
import script from "./kaoqin.vue?vue&type=script&lang=js&"
export * from "./kaoqin.vue?vue&type=script&lang=js&"
import style0 from "./kaoqin.vue?vue&type=style&index=0&id=2591bfbf&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2591bfbf",
  null
  
)

export default component.exports